import { createSlice } from '@reduxjs/toolkit'

export const customerSlice = createSlice({
    name: 'customerData',
    initialState: {
        name: null,
        email: null,
        price: null,
        loading: null,
        url: null,
        membresia: null,
    },
    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setPrice: (state, action) => {
            state.price = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUrl: (state, action) => {
            state.url = action.payload
        },
        setMembresia: (state, action) => {
            state.membresia = action.payload
        },
    }
})

export const { setEmail, setPrice, setLoading, setUrl, setName, setMembresia } = customerSlice.actions

export default customerSlice.reducer